import React from "react";
import './Footer.css'

export default function Footer() {
    return (
        <footer>
            <div className="rodape">
                <div className="menu-container">
                    <ul >
                        <li><a href="https://www.economia.df.gov.br/category/institucional/">Institucional</a></li>
                        <li><a href="https://www.economia.df.gov.br/category/gestao/">Gestão</a></li>
                        <li><a href="https://www.economia.df.gov.br/category/contas-publicas/">Contas Públicas</a></li>
                        <li><a href="https://www.economia.df.gov.br/category/servicos/">Serviços</a></li>
                        <li><a href="https://www.economia.df.gov.br/programas/">Programas</a></li>
                        <li><a href="https://www.economia.df.gov.br/category/servidor/">Servidores</a></li>
                        <li><a href="https://www.economia.df.gov.br/category/comunicacao/">Comunincação</a></li>
                        <li><a href="https://www.economia.df.gov.br/sobre-a-lai/">Transparência</a></li>
                    </ul>
                    <ul className="menu">
                        <li><a href="https://www.economia.df.gov.br/fale-com-a-secretaria/">Fale com a Secretaria</a></li>
                        <li><a href="http://brasilia.df.gov.br/category/acesso-a-informacao/">Acesso à informação</a></li>
                        <li><a href="http://www.ouvidoria.df.gov.br/">Ouvidoria</a></li>
                    </ul>
                    <ul className="menu">
                        <li><a href="http://www.brasilia.df.gov.br/"> Acesso o Portal do Governo do Distrito<br /> Federal</a></li>
                    </ul>

                    <ul className="menu">
                        <a href="https://www.economia.df.gov.br/category/acesso-a-informacao/" > <img src="ico-acesso-info.svg" width={120} /></a>
                    </ul>
                </div>
            </div>
        </footer>
    )
}