import React from "react";
import {Box, Button, Divider, Grid, Modal, Stack, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

const getTargetElement = () => document.getElementById('content-id');


const options = {
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
        margin: Margin.SMALL,
        format: 'A4',
        orientation: 'portrait',
    },
};
export default function GenericModal(props) {
    const {
        close,
        erro,
        titulo,
        subTitulo,
        msgReenviar,
        icon,
        primaryButtom,
        closeButton,
        open,
        pdf
    } = props;

    return (
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={close}
                closeAfterTransition
            >

                <Box sx={styleModal}>
                    <Box id={'content-id'}>
                        <Grid container alignItems="center" justifyContent="center" >
                            <Grid container justifyContent="flex-end" sx={{ marginBottom: -3, marginRight: -3 }}>
                                <Button
                                    onClick={closeButton}
                                >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ marginTop: 0 }}>
                                {icon}
                            </Grid>
                            <Grid item sx={{ marginBottom: '-8px'}}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', marginLeft: 1 }}>
                                    {titulo}
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <Divider />
                        <Grid item sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, display: 'flex', alignContent: 'center' }}  >
                                {subTitulo}
                            </Typography>
                        </Grid>
                        {erro.map((e, key) => (
                            <ul style={{ marginLeft: 25 }} key={key}>
                                <li>
                                    <Typography m={1}>
                                        {e}
                                    </Typography>

                                </li>
                            </ul>

                        ))}
                        {erro?
                            <>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {msgReenviar}
                                </Typography>

                            </>:
                            <>
                            </>
                        }

                        <br />
                        {/* {!onSubModal && erro?.map((a, key) => (
                            <Typography key={key}>{a.charAt(0).toUpperCase() + a.substring(1)};</Typography>
                        ))} */}
                    </Box>
                    <Stack
                        direction="row-reverse"
                        spacing={2}
                    >
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '20ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {pdf &&
                                <Button variant="contained" onClick={() => generatePDF(getTargetElement, options)}>
                                    <PictureAsPdfIcon />
                                </Button>

                            }

                            <Button variant="contained"
                                onClick={primaryButtom}
                                sx={{ fontWeight: '600' }}
                            >
                                SAIR
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
    )

}
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 3,
}