import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Container, Modal, Divider, Grid } from '@mui/material';
import { useNavigate, useOutletContext } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Dropzone from './Dropzone';
import './EnsalementoForm.css'
import AlertDialog from './AlertDialog';
import ArquivoService from '../service/ArquivoService';
import Service from '../service/Service';
import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GenericModal from './GenericModal/GenericModal';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 3
}

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(2)', mr: 2 }}
    >
        •
    </Box>
);

let regisConc = 'ENTRADA';
let validaErro = false;

export default function UploadCSV() {

    const [setMensagemErro] = useOutletContext();
    const [open, setOpen] = React.useState(false);
    const [erro, setErro] = React.useState([])
    const [onSubModal, setOnSubModal] = React.useState(true)
    const [openErroConfirmacao, setOpenErroConfirmacao] = React.useState(false);
    const [data, setData] = React.useState(null)
    const [wait, setWait] = React.useState(false);
    const [mensagem, setMensagem] = React.useState();
    const [anexo, setAnexo] = React.useState();
    const [selectedFileUpload, setSelectedFileUpload] = React.useState();
    const [ifEnviar, setIfEnviar] = React.useState(false);
    const [ifCSV, setIfCSV] = React.useState(false);
    const [arryAnexos, setArryAnexos] = React.useState([]);
    const intervalRef = React.useRef(null);

    const navigate = useNavigate();

    React.useEffect(() => {

        if (selectedFileUpload && selectedFileUpload.length > 0) {
            let arrAne = []
            for (let x in selectedFileUpload) {
                const selecFile = selectedFileUpload[x];
                if (!validarArquivoCSV(selecFile.path)) {
                    setOpen(true);
                    return;
                }

                if (selecFile.type !== "text/csv" && selecFile.type !== "text/plain") {
                    setIfCSV(true);
                    return;
                }

                let dados = new FormData();
                setWait(true);
                dados.append('file', selecFile);
                ArquivoService.upload(dados).then((result) => {
                    setWait(false);
                    var nm = selecFile.name;
                    let anexo = {
                        nome: nm,
                        uuid: result.uuid
                    };
                    arrAne.push(anexo)
                    if (result.status === 500) {
                        setMensagem("Não pode transferir o arquivo! " + result.error)
                    } else {
                        setAnexo(anexo);
                    }
                }).catch((e) => {
                    setWait(false);
                    if (typeof e === 'string') {
                        setMensagemErro(e)
                    } else if (typeof e === 'object') {
                        setMensagemErro(e ? e.message : 'Oops! Something went wrong. Please try again!');
                    } else {
                        setMensagemErro('Oops! Something went wrong. Please try again!');
                    }
                });

            }
            setArryAnexos(arrAne)
            setSelectedFileUpload();
        } else if (selectedFileUpload && selectedFileUpload.length == 0) {
            setArryAnexos()
        }

    }, [selectedFileUpload])

    function teste(r) {
        console.log(regisConc !== "CONCLUSAO")
        intervalRef.current = setInterval(() => {
            if (regisConc !== "CONCLUSAO") {
                console.log(regisConc === "PRE_VALIDACAO_ERRO")
                action(r);
            }
        }, 3000);

        return () => {
            clearInterval(intervalRef.current);

        };


    }




    function validarArquivoCSV(nomeArquivo) {
        var regex = /^(\d{6})_(\d{14})_(\d{2})(\d{2})\.csv$/;
        var resultado = regex.test(nomeArquivo);
        return resultado;
    }

    const upload = () => {
        let file = document.getElementById("file");
        let dados = new FormData();
        setWait(true);
        dados.append('file', file.files[0]);
        ArquivoService.upload(dados).then((result) => {
            setWait(false);
            var nm = file.value.split(/(\\|\/)/g).pop();

            let anexo = {
                nome: nm,
                uuid: result.uuid
            };
            setAnexo(anexo);
            console.log("upload.upload>>>>\nanexo\n", anexo)
            file.value = null;
        }).catch((e) => {
            setWait(false);
            setMensagem({ tipo: 'error', mensagem: e.message });
        });
    }

    const handleClick = () => {
        console.log("entradaArquivo/prepara", arryAnexos)
        if (arryAnexos.length > 0) {
            for (let x in arryAnexos) {
                const arquivoAnexo = arryAnexos[x];
                console.log("entradaArquivo/prepara", arquivoAnexo)
                Service.POST('/api/entradaArquivo/prepara', arquivoAnexo)
                    .then(r => {
                        regisConc = 'ENTRADA';
                        teste(r.id)
                        setWait(true)
                    }).catch(error => {
                        console.log("erro:", error)
                        if (typeof error === 'string') {
                            setMensagemErro(error)
                        } else if (typeof error === 'object') {
                            setMensagemErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
                        } else {
                            setMensagemErro('Oops! Something went wrong. Please try again!');
                        }


                    });
            }
        }

    }

    const handleClose = () => {
        setOpen(false)
    };
    const handleCloseModal = () => {
        // regisConc = 'ENTRADA';
        clearInterval(intervalRef.current);
        validaErro = false;
        setArryAnexos()
        setOpenErroConfirmacao(false)
    };

    const atualizaPagina = () => {
        navigate("/validadorEnsalemento", { state: { key: "value" } });
    }



    function action(r) {
        Service.GET('/api/entradaArquivo/' + r)
            .then(r => {
                setData(r)
                for (let x in r.fluxo) {
                    const fluxo = r.fluxo[x];
                    console.log(fluxo)
                    if (fluxo === 'VALIDACAO') {
                        regisConc = "CONCLUSAO";
                        console.log(erro)
                        setOpenErroConfirmacao(true);
                        console.log("pre-confirmacao concluida ")
                        setWait(false)
                    } else if (fluxo === 'PRE_VALIDACAO_ERRO') {
                        validaErro = true
                        regisConc = "CONCLUSAO";
                        setWait(false)

                    }

                    if (r && r.erro) {
                        const novosErros = [];
                        for (let x in r.erro) {
                            const msgErro = r.erro[x];
                            const valor = msgErro.split(';');
                            const ultimoValor = valor[valor.length - 1]

                            novosErros.push(ultimoValor);
                        }
                        setErro(novosErros);
                        setOpenErroConfirmacao(true)
                    }

                }

            }).catch(error => {
                if (typeof error === 'string') {
                    setMensagem(error)
                } else if (typeof error === 'object') {
                    setMensagem(error);
                } else {
                    setMensagem('Oops! Something went wrong. Please try again!');
                }

            });
    }


    const msg = {
        iconErro: (<ReportProblemIcon sx={{ fontSize: 40, color: '#ff9966' }} />),
        titulo: 'Erros encontrados',
        subTitulo: (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Os erros abaixo não atendem o formato obrigatório exigido pela&nbsp;
                <a style={{ textDecoration: 'none', color: '#6BA7ED' }} target="_blank" rel="noopener noreferrer" href='https://ww1.receita.fazenda.df.gov.br/legislacao/visualizar-legislacao?txtNumero=16&txtAno=2019&txtTipo=8&txtParte=SUREC&identificacao=Instru%C3%A7%C3%A3o%20Normativa%20n%C2%BA%2016%2F2019%20SUREC'>
                    Instrução Normativa SUREC/SEF/SEEC N° 16
                </a>
            </Typography>
        ),
        iconSucesso: (<CheckCircleIcon sx={{ fontSize: 40, color: '#99CC33' }} />),
        tituloSucesso: 'Sucesso',
        subTituloSucesso: `Será iniciado o processamento e validação das solicitações realizadas.`,
        msgReenviar: 'O arquivo selecionado precisa ser corrigido antes de prosseguir. Por favor, revise e ajuste o arquivo conforme necessário para dar seguimento.'
    };




    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                {mensagem && <Alert onClose={() => { setMensagem() }} severity="error">{mensagem} <br />Confirme o tamanho do arquivo.</Alert>}
                <Dropzone onFileUploaded={setSelectedFileUpload} data={data} />
                <br />
                <Stack
                    direction="row-reverse"
                    spacing={2}

                >

                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Button variant="contained" endIcon={<SendIcon />} onClick={handleClick}>
                            Enviar
                        </Button>
                    </Box>
                    <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/csv" />
                </Stack>
            </Container>

            <AlertDialog open={ifEnviar} setOpen={setIfEnviar} btnLabel={"OK"} actionOnClick={atualizaPagina} >
                <Typography gutterBottom>
                    Informe a quantidade de instancias e um arquivo .CSV
                </Typography>
            </AlertDialog>

            <AlertDialog open={ifCSV} setOpen={setIfCSV} btnLabel={"OK"} actionOnClick={atualizaPagina} >

                Arquivo inválido!
                <br />
                Informe um Arquivo .CSV ou .TXT

            </AlertDialog>
            <Grid container spacing={2} justifyContent="center" alignItems="center" >


                {validaErro === true ?
                    <GenericModal
                        open={openErroConfirmacao}
                        onSubModal={onSubModal}
                        erro={erro}
                        icon={msg.iconErro}
                        titulo={msg.titulo}
                        subTitulo={msg.subTitulo}
                        closeButton={handleCloseModal}
                        primaryButtom={handleCloseModal}
                        dado={data}
                        close={handleCloseModal}
                        msgReenviar={msg.msgReenviar}
                    />


                    :
                    <GenericModal
                        open={openErroConfirmacao}
                        onSubModal={onSubModal}
                        erro={[]}
                        icon={msg.iconSucesso}
                        titulo={msg.tituloSucesso}
                        subTitulo={msg.subTituloSucesso}
                        closeButton={() => navigate('/consultaSubTrib')}
                        primaryButtom={() => navigate('/consultaSubTrib')}
                        dado={data}
                        pdf={false}
                        close={() => navigate('/consultaSubTrib')}
                    />

                }
            </Grid>
        </div>
    );
}
