import React from "react";
import './Footer.css'

export default function Footer() {
    return (
        <footer>
            <div className="rodapeLogin">
                <div className="menu-container">
                    <ul >
                        <li><a href="https://www.economia.df.gov.br/category/institucional/">Institucional</a></li>
                        
                    </ul>
                    <ul className="menu">
                        <li><a href="https://www.economia.df.gov.br/fale-com-a-secretaria/">Fale com a Secretaria</a></li>
                       
                    </ul>
                    <ul className="menu">
                        <li><a href="http://www.brasilia.df.gov.br/"> Acesso o Portal do Governo do Distrito Federal</a></li>
                    </ul>

                    <ul className="menu">
                       <a href="https://www.economia.df.gov.br/category/acesso-a-informacao/" > <img src="ico-acesso-info.svg" width={120} /></a>
                    </ul>
                </div>
            </div>
        </footer>
    )
}