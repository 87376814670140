import logo from './logo.svg';
import './App.css';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainAppBar from './components/MainAppBar';
import Settings from './components/Settings';
import UploadCSV from './components/UploadCSV';
import AcompanharProcesso from './components/AcompanharProcesso';
import SubTributariaTable from './components/SubTributariaTable';
import DetalheSubistituicaoTributaria from './components/DetalheSubistituicaoTributaria';
import VerArquivos from './components/VerArquivos';
import Login from './components/Login/Login';
import Register from './components/Register/Register'
import Home from './components/Home';

const router = createBrowserRouter([
  {
    path: "/login",
    element: < Login />,
  },
  {
    path: "/register",
    element: < Register />,
  },
  {
    path: "/",
    element: < MainAppBar/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "map",
        element: < PaginaB />,
      },
      {
        path: "upload",
        element: < UploadCSV />,
      },
      {
        path: "settings",
        element: < Settings />,
      },
      {
        path: "/acompanhaProcessamento/:id",
        element: <AcompanharProcesso/>
      },
      {
        path: "/arquivos",
        element: <VerArquivos/>
      },
      {
        path:"/detalheSubistituicao/:id",
        element: <DetalheSubistituicaoTributaria/>
      },
      {
        path: "consultaSubTrib",
        element: < SubTributariaTable />,
      },
      {
        path: "home",
        element: < Home    />,
      },
    ]
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;


function ErrorPage() {
  return (
    <>
      <div id="sidebar">
        <h1>Pagina não encontrado</h1>
        <nav>
          <ul>
            <li>
              <a href={`/`}>home</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
function PaginaFake() {
  return (
    <>
      <div id="sidebar">
        <h1>Caiu na pegadinha da pagina: </h1>
        <h4 > Bananão</h4>
        <nav>
          <ul>
            <li>
              <a href={`/`}>home</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
function PaginaB() {
  return (
    <>
      <div id="sidebar">
        <h1>Pagina 2 não encontrado</h1>
        <p> brinks....</p>
        <nav>
          <ul>
            <li>
              <a href={`/`}>home</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}