import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


export function FormataDataSemHora(data) {
  const newData = new Date(data)
  var dia = newData.getDate().toString(),
    diaF = (dia.length == 1) ? '0' + dia : dia,
    mes = (newData.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length == 1) ? '0' + mes : mes,
    anoF = newData.getFullYear();


  return diaF + "/" + mesF + "/" + anoF + " ";
}

export function FormataCNPJ(data) {
  // Remove caracteres não numéricos
  data = data.replace(/\D/g, '');

  // Aplica a formatação
  return data.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}


export function FormataTelefone(numero) {
  const apenasDigitos = numero.replace(/\D/g, '');

  // Aplica a formatação específica para números de celular no Brasil
  if(numero.length > 10 ){
    return apenasDigitos.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');


  }else{
    return apenasDigitos.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

}
reportWebVitals();
