const ArquivoService = {
    upload: function(arquivo) {
        return fetch(`/api/upload/file`, {
            method: "POST",
            body: arquivo,
            headers: {
                Authorization: `Bearer ${localStorage.accessToken}` 
            }
          }).then(async (r) => {
            
            if(r.status === 401){

                ArquivoService.gotoLogin();
            }
            if (!r.ok) {
                console.log(r)
                return r.json();
            }
            return r.json();
        });
    },

    gotoLogin: function(){
        localStorage.clear();
        throw {
            tipo: 'alertDialog',
            mensagem: 'Realize o login novamente.',
            titulo: "Sua sessão expirou!",
            action: ()=> window.location.href = "/login"
        };
    }
};

export default ArquivoService;
