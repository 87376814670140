import * as React from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './../App.css'
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import AlertDialog from './AlertDialog';






export default function MainAppBar() {

    const [open, setOpen] = React.useState(false)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [perfil, setPerfil] = React.useState(false)
    const [mensagemAlerta, setMensagemAlerta] = React.useState({
        tipo: 'info',
        duracao: 5000,
        mensagem: "Mensagem não anexada"
    })

    React.useEffect(() => {
        const token = localStorage.getItem("accessToken")
        const per = localStorage.getItem("perfil")
        // console.log("test", token)
        if (!token) {
            window.location.href = "/login";
        }
        if (per) {
            setPerfil(per)
        }
    }, [])



    function setMensagem(alerta) {
        // console.log("Mensagem: ", alerta);
        let a;
        if (typeof alerta === 'object') {
            a = {
                tipo: alerta && alerta.tipo ? alerta.tipo : "info",
                duracao: alerta && alerta.duracao ? alerta.duracao : 5000,
                mensagem: alerta && alerta.mensagem ? alerta.mensagem : "Mensagem não anexada",
                titulo: alerta && alerta.titulo ? alerta.titulo : false,
                action: alerta && alerta.action ? alerta.action : false,
            }
            if (alerta && alerta.tipo == "alertDialog") {
                setMensagemAlerta(a);
                setOpenDialog(true);
                return;
            }


        } else {
            a = {
                tipo: 'error',
                duracao: 5000,
                mensagem: alerta
            }
        }
        setOpen(true);
        setMensagemAlerta(a);
        if (a && !!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setOpen(false);
            }, a.duracao)
        }
    }

    const handleClose = () => {
        if(mensagemAlerta.action){
            mensagemAlerta.action();
        }
        //setOpenDialog(false);
    };

    return (<>
        <Header perfil={perfil} />
        {open &&
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={mensagemAlerta.tipo} onClose={() => { setOpen(false) }} >
                    {mensagemAlerta.titulo && <AlertTitle>{mensagemAlerta.titulo}</AlertTitle>}
                    {mensagemAlerta.mensagem}
                </Alert>
            </Stack>
        }
        <AlertDialog open={openDialog} setOpen={setOpenDialog} actionOnClick={handleClose} title={mensagemAlerta.titulo} >
            <Typography gutterBottom>
                {mensagemAlerta.mensagem}
            </Typography>
        </AlertDialog>
        <div className='corpo-aplicacao' id="sidebar">

            <nav>
                {/* <ul>
                    <li>
                        <a href={`/consultaSubTrib`}>Consulta Subiscrição Tributária</a>
                    </li>
                    <li>
                        <a href={`/grafico`}>grafico</a>
                    </li>
                    <li>
                        <a href={`/settings`}>settings</a>
                    </li>
                    <li>
                        <a href={`/banana`}>pagina inexistente</a>
                    </li>
                </ul> */}
                <Outlet context={[setMensagem]} />
            </nav>
        </div>
        <div id="detail"></div>
        <Footer />
    </>);
}