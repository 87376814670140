import * as React from 'react'

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Service from '../service/Service';
import ReactFlow, {
    Position
} from 'reactflow';
import 'reactflow/dist/style.css';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GenericModal from './GenericModal/GenericModal';
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Button,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";


const style = {
    background: '#2B6CB0',
    color: 'white',
    width:150
};
const styleErro = {
    background: 'red',
    color: 'white',
    width:150
};
let interval = 0;
let initialNodes = [
    {
        id: '1', position: { x: 100, y: 50 }, data: { label: 'Entrada' },
        type: 'input',
        className: 'circle',
        style: { background: '#2B6CB0', color: 'white',width:150 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,

    },
    {
        id: '2', position: { x: 300, y: 50 }, data: { label: 'Validação' },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
    },
    {
        id: '3', position: { x: 500, y: 50 }, data: { label: 'Estruturação' },
        type: 'output',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
    },
];
const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e2-3', source: '2', target: '3' }
];


const dadosTabela = [
    { coluna1: "Dado 1", coluna2: "Dado 2", coluna3: "Dado 3" },
    { coluna1: "Dado 4", coluna2: "Dado 5", coluna3: "Dado 6" },
    // Adicione mais linhas conforme necessário
];


let regisConc = 'ENTRADA';

    export default function AcompanharProcesso() {
    var idAquivo;
    var valorArquivo;
    const { id } = useParams();
    const [setMensagem] = useOutletContext();
    const [registroConcluido, setRegistroConcluido] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const [erro, setErro] = React.useState([])
    const [data, setData] = React.useState(null)
    const [nodes, setNodes] = React.useState();
    const [onSubModal, setOnSubModal] = React.useState(true)
    const [dado, setDado] = React.useState()
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setOpen(false);
    React.useEffect(() => {
        action();
        if (interval === 0) {
        }

    }, [])
    React.useEffect(() => {

        const timer = setInterval(() => {
            if (registroConcluido != "CONCLUSAO") {
                action();

                getProtocolo();

            }
        }, 3000);

        return () => {
            clearInterval(timer);

        };
    }, [registroConcluido]);


    const mensagem = {
        iconErro: (<ReportProblemIcon />),
        titulo: 'Erros encontrados',
        subTitulo: (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Os erros abaixo não atendem o formato obrigatório exigido pela&nbsp;
                <a style={{ textDecoration: 'none', color: '#6BA7ED' }} target="_blank" rel="noopener noreferrer" href='https://ww1.receita.fazenda.df.gov.br/legislacao/visualizar-legislacao?txtNumero=16&txtAno=2019&txtTipo=8&txtParte=SUREC&identificacao=Instru%C3%A7%C3%A3o%20Normativa%20n%C2%BA%2016%2F2019%20SUREC'>
                    Instrução Normativa SUREC/SEF/SEEC N° 16
                </a>
            </Typography>
        ),
        iconSucesso: (<DoneIcon />),
        tituloSucesso: 'Registro concluído com sucesso.',
        subTituloSucesso: `O Protocolo: ${data?.protocolo} foi registrado com sucesso!`
    };


    function action() {
        Service.GET('/api/entradaArquivo/' + id)
            .then(r => {
                setDado(r)
                idAquivo = r.id;
                const sty = r.erro ? styleErro : style
                let respNodes = [...initialNodes];
                
                for (let x in r.fluxo) {
                    const fluxo = r.fluxo[x];
                    console.log(fluxo)
                    if (fluxo === 'VALIDACAO') {
                        respNodes[0].style = sty;
                    } else if (fluxo === 'PRE_VALIDACAO') {
                        respNodes[1].style = sty;
                    } else if (fluxo === 'PRE_VALIDACAO_ERRO') {
                        respNodes[2].style = sty;
                        clearInterval(interval);
                        regisConc = "CONCLUSAO"
                    }
                }
                if (r && r.erro) {
                    const novosErros = [];
                    for (let x in r.erro) {
                        const msgErro = r.erro[x];
                        const valor = msgErro.split(';');
                        const ultimoValor = valor[valor.length - 1]

                        novosErros.push(ultimoValor);
                    }
                    setErro(novosErros);
                    setOpen(true)
                    regisConc = "CONCLUSAO"
                } else if (registroConcluido && regisConc == "CONCLUSAO") {
                    setOpen(true)
                }

                setNodes(respNodes);
                setRegistroConcluido(regisConc);
            }).catch(error => {
                if (typeof error === 'string') {
                    setMensagem(error)
                } else if (typeof error === 'object') {
                    setMensagem(error);
                } else {
                    setMensagem('Oops! Something went wrong. Please try again!');
                }

            });
    }

    function getProtocolo() {
        Service.POST('/api/subTributaria/filtro', { teste: 'alalal' })
            .then(r => {
                let valor = r.find((element) => (element.id_arquivo == idAquivo));
                valorArquivo = valor;
                setData(valor)
            }).catch(error => {
            });
    }
    return (
        <Container maxWidth="md"> {/* Alterado para 'md' para maior largura */}
            <Typography variant="h5" align="center" gutterBottom>
                Arquivo sendo processado : {dado?.nome}
            </Typography>

            <Grid style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <div style={{ width: '900px', height: '200px' }}>
                    <ReactFlow nodes={nodes} edges={initialEdges} >
                    </ReactFlow>

                </div>
            </Grid>


            {open && erro.length < 1 ?
                <GenericModal
                    open={open}
                    onSubModal={onSubModal}
                    //close={() => navigate('/detalheSubistituicao/' + data.id )}
                    erro={erro}
                    icon={mensagem.iconSucesso} 
                    titulo={mensagem.tituloSucesso}
                    subTitulo={mensagem.subTituloSucesso}
                    //closeButton={() => navigate('/detalheSubistituicao/' + data.id )}
                    primaryButtom={() => navigate('/detalheSubistituicao/' + data.id )}
                    dado={dado}
                />
                :
                <GenericModal
                    open={open}
                    onSubModal={onSubModal}
                    erro={erro}
                    icon={mensagem.iconErro}
                    titulo={mensagem.titulo}
                    subTitulo={mensagem.subTitulo}
                    //closeButton={() => navigate('/upload')}
                    //primaryButtom={() => navigate('/upload')}
                    dado={dado}
                />
            }
        </Container>

    )
}