import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider, Grid, Modal, Stack } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';



export default function AlertDialog(props) {
  const { open, setOpen, title, btnLabel, actionOnClick } = props;

  const handleClick = () => {
    if (actionOnClick) actionOnClick();
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent="center" alignItems="center" >
        <Modal open={open} onClose={handleClick}>
          <Box sx={styleModal}>
            <Box id={'content-id'}>
              <Grid container alignItems="center" justifyContent="center">
                <WarningIcon />
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', marginLeft: 1 }}>
                  {title}
                </Typography>
              </Grid>
              <br />
              <Divider />
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2 }}>
                {props.children}
              </Typography>
            </Box>
            <Stack direction="row-reverse" spacing={2}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '20ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <Button variant="contained"
                  onClick={handleClick}
                  sx={{ fontWeight: '600' }}
                >
                  {btnLabel ? btnLabel : "Fechar"}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </Grid>
    </React.Fragment>
  );
}

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
}