import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import './Header.css'

export default function Header(props) {

    const { perfil } = props;

    function logout() {
        localStorage.removeItem("accessToken")
        window.location.href = "/login";
    }

    function geraMenu() {
        if (perfil === "seec") {
            return (
                <ul style={{ marginLeft: '23vh' }}>
                    <li>
                        <a href={`/home`}>Início</a>
                    </li>
                    <li>
                        <a href={`/consultaSubTrib`}>Gerenciar Solicitações</a>
                    </li>
                    <li>
                        <a href={`/arquivos`}>Relatórios</a>
                    </li>

                </ul>
            );
        } else if (perfil === "admin") {
            return (
                <ul sstyle={{ marginLeft: '23vh' }}>
                    <li>
                        <a href={`/home`}>Início</a>
                    </li>
                    <li>
                        <a href={`/upload`}>Solicitar ICMS-ST</a>
                    </li>
                    <li>
                        <a href={`/Relatorio`}>Relatórios</a>
                    </li>
                    <li>
                        <a href={`/consultaSubTrib`}>Gerenciar Solicitações</a>
                    </li>
                    <li>
                        <a href={`/consultaSubTrib`}>Minhas substituições</a>
                    </li>
                </ul>
            );
        } else if (perfil === "empresario") {
            return (
                <ul style={{ marginLeft: '23vh' }}>
                    <li>
                        <a href={`/home`}>Início</a>
                    </li>
                    <li>
                        <a href={`/consultaSubTrib`}>Minhas substituições</a>
                    </li>
                    <li>
                        <a href={`/upload`}>Solicitar ICMS-ST</a>
                    </li>

                </ul>
            );
        } else {
            return (
                <ul style={{ marginLeft: '23vh' }}>
                    <li>
                        <a href={`/home`}>Usuario sem perfil</a>
                    </li>
                    
                </ul>
            );
        }
    }

    return (
        <header>
            <div className="topo-site">
                <ul>
                    <li><a href="http://www.transparencia.df.gov.br/" target="_blank" rel="noreferrer">Transparência</a></li>
                    <li><a href="http://www.ouvidoria.df.gov.br/" target="_blank" rel="noreferrer">Ouvidoria</a></li>
                    <li><a href="http://www.df.gov.br/category/acesso-a-informacao/" rel="noreferrer">Acesso à Informação</a></li>
                    <li><a href="https://www.dodf.df.gov.br/" target="_blank" rel="noreferrer">Diário Oficial</a></li>
                    <li><a href="https://www.agenciabrasilia.df.gov.br/" target="_blank" rel="noreferrer">Agência Brasília</a></li>
                    <li><a href="http://www.brasilia.df.gov.br" target="_blank" rel="noreferrer"> Portal do Governo de Brasília</a></li>
                    <li><a href="http://www.dados.df.gov.br/" target="_blank" rel="noreferrer">Dados Abertos</a></li>
                    <li><a href="http://www.lgpd.df.gov.br/" target="_blank" rel="noreferrer">LGPD</a></li>
                    <li><a href="https://www.pcdf.df.gov.br/servicos/delegacia-eletronica/violencia-domestica-contra-mulher" target="_blank" rel="noreferrer">Maria da Penha Online</a></li>

                    <li onClick={() => logout()} className="icon-logout"><LogoutIcon style={{ color: '#ccc' }} /></li>
                </ul>
            </div>
            <div className="topo-site-title">
                <h1>
                    SECRETARIA DE ESTADO DE ECONOMIA
                </h1>
                <p>
                    SEEC
                </p>
            </div>
            <div className="topo-site-logo">
                <a href="/home">
                    <img src="ico-logo-gdf.svg" width={185} alt="Logotipo do Governo do Distrito Federal" />
                </a>
            </div>
            <div className="menu-itens">
                <div style={{ backgroundColor: '#4079bc' }}>
                    {geraMenu()}

                </div>
            </div>
        </header>
    )
}