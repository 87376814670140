import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,


} from "@mui/material";

import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import Service from '../service/Service';
import { FormataCNPJ, FormataTelefone } from '..';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last borderimport VisibilityIcon from '@mui/icons-material/Visibility';
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DetalheSubistituicaoTributaria() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [setMensagem] = useOutletContext();
  const [sub, setSub] = React.useState();
  const [errosArq, setErrosArq] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [row, setRow] = React.useState();
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    Service.GET('/api/subTributaria/byId/' + id)
      .then(r => {
        setSub(r.subiscricaoTributaria);
        listaDeErros(r.entradaArquivo);
        // console.log(r)
        setRow(r.subiscricaoTributaria.detalhe.slice(0, rowsPerPage));
      }).catch(error => {
        // console.log("erro:", error)
        if (typeof error === 'string') {
          setMensagem(error)
        } else if (typeof error === 'object') {
          setMensagem(error);
        } else {
          setMensagem('Oops! Something went wrong. Please try again!');
        }
      });
  }, [])

  function formatarMoeda(valor) {
    if (valor) return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    return "";
  }
  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
      const arr = sub.detalhe.slice(0, rowsPerPage);
      setRow(arr);
    } else {
      const arr = sub.detalhe.slice(newPage * rowsPerPage, (1 + newPage) * rowsPerPage);
      setRow(arr);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value === -1) {
      if (sub.detalhe.length > 100) {
        alert("Maximo da pagina é 100");
        const arr = sub.detalhe.slice(0, 100);
        setRow(arr);
        setRowsPerPage(100);
        setPage(0);
      }
    }
    const arr = sub.detalhe.slice(0, event.target.value);
    setRow(arr);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const chamarPdf = () => {
    fetch(`/api/pdf/relatorioFinal?id=${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/pdf',
        }
    })
    .then(response => {
      console.log(response)
        if (response.ok) {
            return response.blob();
        }
        throw new Error('Failed to fetch PDF');
    })
    .then(blob => {
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        console.log(url)


        if (!blob) return;
            var file = window.URL.createObjectURL(blob);
            let tab = window.open();
            tab.location.assign(file);
    })
    .catch(error => {
        console.error('Erro ao gerar PDF:', error);
    });
};


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function buscaStatus(r) {
    if (errosArq.length > 0) {
      let resp = errosArq.find((e) => e.nfe == r);
      if (resp) {
        return resp.msg;
      }
    }

    return "Nota Escriturada";
  }


  function listaDeErros(arquivo) {
    let arrResp = [];
    if (arquivo.erro) {
      for (let x in arquivo.erro) {
        const linha = arquivo.erro[x].split(";");
        arrResp.push({
          resp: linha[1],
          tipo: linha[2],
          msg: linha[3],
          nfe: linha[4] ? linha[4] : ""
        })
      }
      // arrResp = arquivo.erro;
    }
    setErrosArq(arrResp);
  }
  return (
    <Container maxWidth="md"> {/* Alterado para 'md' para maior largura */}
      <Typography variant="h5" align="center" gutterBottom>
        Dados arquivo de validação ICMS-ST
      </Typography>
      {sub &&


        <div style={{ marginTop: '50px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ padding: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField label="CNPJ:" disabled fullWidth style={{ marginBottom: 10 }} value={FormataCNPJ(sub.cnpj)} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Razão Social:" disabled fullWidth style={{ marginBottom: 10 }} value={sub.nome} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Nome do Responsável:" disabled fullWidth style={{ marginBottom: 10 }} value={sub.nome} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Telefone:" fullWidth disabled style={{ marginBottom: 10 }} value={FormataTelefone(sub.telefone)} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div >
      }
      <div style={{ marginTop: '50px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: '#4079bc' }}>
                <TableCell style={{ width: '70%' }}>Nota</TableCell>
                <TableCell style={{ width: '15%' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row && row.map((row) => (
                <StyledTableRow key={row.nfe} >
                  <TableCell>{row.nfe}</TableCell>
                  <TableCell>{buscaStatus(row.nfe).replace("Nota", "")}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                {sub &&
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={"Linhas por página"}
                    colSpan={5}
                    count={sub && sub.detalhe && sub.detalhe.length ? sub.detalhe.length : 0}
                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'Linhas por pagina',
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                }
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <div style={{ marginTop: '25px', textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={() => chamarPdf()}>Imprimir</Button>
        <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={() => navigate('/consultaSubTrib')}>Sair</Button>
      </div>
    </Container>
  );
}