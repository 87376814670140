import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip, IconButton, Typography, Box, Card, CardActions, CardContent, Grid } from '@mui/material';
import Service from '../service/Service';
import { useNavigate, useOutletContext } from "react-router-dom";
import SearchField from './SearchField';
import TablePaginationActions from './TablePaginationActions';
import { FormataCNPJ, FormataDataSemHora, FormataTelefone } from '..';
import DetalheSubistituicaoTributaria from './DetalheSubistituicaoTributaria';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4079bc',
    color: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
    cursor: 'pointer',
  },
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'relative',
  margin: '80px',
});

const ButtonContainer = styled('div')({
  marginTop: 10,
});

const SearchContainer = styled('div')({
  position: 'absolute',
  top: '-90px', // Alinha 15px acima
  left: '0px',
});

export default function SubTributariaTable() {
  const navigate = useNavigate();
  const [setMensagem] = useOutletContext();
  const [lista, setLista] = React.useState();
  const [listaFiltro, setListaFiltro] = React.useState();
  const [row, setRow] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [listaEmProcessamento, setListaEmProcessamento] = React.useState();
  const [rowsPerPageProcessamento, setRowsPerPageProcessamento] = React.useState(5);
  const [rowProcessamento, setRowProcessamento] = React.useState();
  const [pageProcessamento, setPageProcessamento] = React.useState(0);
  const [listaFiltroProcessamento, setListaFiltroProcessamento] = React.useState();






  React.useEffect(() => {
    Service.POST('/api/subTributaria/filtro', { teste: 'alalal' })
      .then(r1 => {
        setLista(r1);
        setListaFiltro(r1);
        Service.GET('/api/entradaArquivo/todos')
          .then(r2 => {
            const combinedResults = r1.map((item, index) => {
              const processamento = r2[index];
              return {
                ...item,
                ...processamento,
                subTributariaId: item.id
              };
            });
            console.log(combinedResults)
            setLista(combinedResults);
            setListaFiltro(combinedResults);
            setRow(combinedResults.slice(0, rowsPerPage));
            let lsProcessamento = [];
            for (let x in r2) {
              const processamento = r2[x];
              let lastProcessamento = (processamento.fluxo.length - 1);

              if (processamento.fluxo[lastProcessamento] === 'CONCLUSAO') continue;
              if (processamento.fluxo[lastProcessamento] === 'PRE_VALIDACAO_ERRO') continue;
              if (processamento.fluxo[lastProcessamento] === 'PRE_VALIDACAO') continue;
              lsProcessamento.push({ ...r1[x], ...processamento });
            }
            setListaFiltroProcessamento(lsProcessamento);
            setListaEmProcessamento(lsProcessamento);
            setRowProcessamento(lsProcessamento.slice(0, rowsPerPageProcessamento));
          })
          .catch(error => {
            console.error("Erro ao buscar dados de entradaArquivo:", error);
          });

      }).catch(error => {
        if (typeof error === 'string') {
          setMensagem(error)
        } else if (typeof error === 'object') {
          setMensagem(error);
        } else {
          setMensagem('Oops! Something went wrong. Please try again!');
        }

      });

  }, [])

  function formatarMoeda(valor) {
    if (valor) return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    return "";
  }


  const handleDoubleClick = (item) => {
    console.log(item);
    navigate("/detalheSubistituicao/" + item.subTributariaId, { state: { key: "value" } });
  }
  const handleFiltro = (txt) => {
    console.log(txt);
  }
  const handleFindOnPress = (txt) => {
    console.log(txt, lista);
    let arr = [];
    for (let x in lista) {
      const item = lista[x];
      for (let h in item) {
        console.log(h);
        if (item[h] && (item[h] + "").includes(txt)) {
          arr.push(item);
          break;
        }
      }
    }
    setListaFiltro(arr);
    arr = arr.slice(0, rowsPerPage);
    setRow(arr);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
      const arr = listaFiltro.slice(0, rowsPerPage);
      setRow(arr);
    } else {
      const arr = listaFiltro.slice(newPage * rowsPerPage, (1 + newPage) * rowsPerPage);
      setRow(arr);
    }
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    if (event.target.value === -1) {
      if (listaFiltro.length > 100) {
        alert("Maximo da pagina é 100");
        const arr = listaFiltro.slice(0, 100);
        setRow(arr);
        setRowsPerPage(100);
        setPage(0);
      }
    }
    const arr = listaFiltro.slice(0, event.target.value);
    setRow(arr);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleChangePageProcessamento = (event, newPage) => {
    if (newPage === 0) {
      const arr = listaEmProcessamento.slice(0, rowsPerPageProcessamento);
      setRowProcessamento(arr);
    } else {
      const arr = listaEmProcessamento.slice(newPage * rowsPerPageProcessamento, (1 + newPage) * rowsPerPageProcessamento);
      setRowProcessamento(arr);
    }
    setPageProcessamento(newPage);
  };

  const handleChangeRowsPerPageProcessamento = (event) => {
    if (event.target.value === -1) {
      if (listaEmProcessamento.length > 100) {
        alert("Maximo da pagina é 100");
        const arr = listaEmProcessamento.slice(0, 100);
        setRowProcessamento(arr);
        setRowsPerPageProcessamento(100);
        setPageProcessamento(0);
      }
    }
    const arr = listaEmProcessamento.slice(0, event.target.value);
    setRowProcessamento(arr);
    setRowsPerPageProcessamento(parseInt(event.target.value, 10));
    setPageProcessamento(0);
  }

  function formatarCelular(numero) {
    // Remove todos os caracteres que não sejam dígitos
    const apenasDigitos = numero.replace(/\D/g, '');

    // Aplica a formatação específica para números de celular no Brasil
    const celularFormatado = apenasDigitos.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

    return celularFormatado;
}




  return (
    <Container sx={{ marginBottom: 5 }}>
      <SearchContainer>
        <SearchField
          doAction={handleFiltro}
          findOnPress={handleFindOnPress}
        />
      </SearchContainer>
      <TableContainer component={Paper}>

        <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Protocolo</StyledTableCell>
              <StyledTableCell>CNPJ</StyledTableCell>
              <StyledTableCell>Nome Arquivo</StyledTableCell>
              <StyledTableCell>Telefone</StyledTableCell>
              <StyledTableCell>Data Solicitação</StyledTableCell>
              <StyledTableCell>Ação</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row && row.map((row) => (
              <StyledTableRow key={row.id} onDoubleClick={() => handleDoubleClick(row)}>
                <StyledTableCell>{row?.protocolo}</StyledTableCell>
                <StyledTableCell>{FormataCNPJ(row.cnpj)}</StyledTableCell>
                <StyledTableCell>{row.nome}</StyledTableCell>
                <StyledTableCell>{FormataTelefone(row.telefone)}</StyledTableCell>
                <StyledTableCell>{FormataDataSemHora(row?.dtInclusao)}</StyledTableCell>
                <StyledTableCell>
                  <IconButton size='small' onClick={() => handleDoubleClick(row)} >
                    <VisibilityIcon />
                  </IconButton>

                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              {lista &&
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={"Linhas por página"}
                  labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                  colSpan={6}
                  count={listaFiltro.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'Linhas por pagina',
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              }
            </TableRow>
          </TableFooter>

        </Table>
      </TableContainer>

      {/* Botão no canto inferior direito */}

      <ButtonContainer >
        <Button variant="contained" color="primary" onClick={() => navigate('/upload')}>
          nova solicitação
        </Button>
      </ButtonContainer>



      {listaEmProcessamento?.length ?

        <TableContainer component={Paper} sx={{ marginTop: 5, textAlign: 'center' }}>

          <Typography variant="h2" component="h2" sx={{ marginBottom: 5 }}>
            Em Processamento
          </Typography>

          <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Data entrada</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowProcessamento && rowProcessamento.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row?.nome}</StyledTableCell>
                  <StyledTableCell>Em Validação</StyledTableCell>
                  <StyledTableCell>{FormataDataSemHora(row?.dtInclusao)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                {listaEmProcessamento &&
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={"Linhas por página"}
                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                    colSpan={6}
                    count={listaFiltroProcessamento.length}
                    rowsPerPage={rowsPerPageProcessamento}
                    page={pageProcessamento}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'Linhas por pagina',
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePageProcessamento}
                    onRowsPerPageChange={handleChangeRowsPerPageProcessamento}
                    ActionsComponent={TablePaginationActions}
                  />
                }
              </TableRow>
            </TableFooter>

          </Table>
        </TableContainer> : null}



    </Container>

  );
}
