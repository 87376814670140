import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useOutletContext } from 'react-router-dom';


export default function Settings() {
    const [setMensagem] = useOutletContext();
    return (
        <>
            <div id="sidebar">
                <h1>Congfigurações da pagina</h1>
                <p>An {"<Outlet>"} should be used in parent route elements to render their<br />
                    child route elements. This allows nested UI to show up when child routes<br />
                    are rendered. If the parent route matched exactly, it will render a child<br />
                    index route or nothing if there is no index route.</p>
                <nav>
                    <ul>
                        <li>
                            <a href={`/`}>home</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={() => setMensagem({
                    tipo: "success",
                    msg: "Mensagem de sucesso"
                })} >Sucesso </Button>
                <Button variant="contained" onClick={() => setMensagem({
                    tipo: "info",
                    msg: "Mensagem de Informação"
                })} >Informação</Button>
                <Button variant="contained" onClick={() => setMensagem({
                    tipo: "warning",
                    msg: "Mensagem de Alerta"
                })} >Alerta</Button>
                <Button variant="contained" onClick={() => setMensagem({
                    tipo: "error",
                    msg: "Mensagem de Erro"
                })} >Erro</Button>
                <Button variant="contained" onClick={() => setMensagem("Mensagem de erro")} >texto</Button>
            </Stack>
        </>
    );
}