import React from "react";
import './Header.css'

export default function HeaderLogin() {
   
    return (
        <header>
            <div className="topo-site">
                <ul>
                    <li><a href="http://www.transparencia.df.gov.br/" target="_blank">Transparência</a></li>
                    <li><a href="http://www.ouvidoria.df.gov.br/" target="_blank">Ouvidoria</a></li>
                    <li><a href="http://www.df.gov.br/category/acesso-a-informacao/">Acesso à Informação</a></li>
                    <li><a href="https://www.dodf.df.gov.br/" target="_blank">Diário Oficial</a></li>
                    <li><a href="https://www.agenciabrasilia.df.gov.br/" target="_blank">Agência Brasília</a></li>
                    <li><a href="http://www.brasilia.df.gov.br" target="_blank"> Portal do Governo de Brasília</a></li>
                    <li><a href="http://www.dados.df.gov.br/" target="_blank">Dados Abertos</a></li>
                    <li><a href="http://www.lgpd.df.gov.br/" target="_blank">LGPD</a></li>
                    <li><a href="https://www.pcdf.df.gov.br/servicos/delegacia-eletronica/violencia-domestica-contra-mulher" target="_blank">Maria da Penha Online</a></li>
                </ul>
            </div>
        </header>
    )
}