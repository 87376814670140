import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Service from "../../service/Service";
import { Alert, AlertTitle, Stack } from "@mui/material";
import HeaderLogin from '../Header/HeaderLogin'
import FooterLogin from '../Footer/FooterLogin'

export const ACCESS_TOKEN = 'accessToken';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
    const [user, setUser] = React.useState({
        login: '',
        password: ''
    });
    const [open, setOpen] = React.useState(false)
    const [mensagemAlerta, setMensagemAlerta] = React.useState({
        tipo: 'info',
        duracao: 5000,
        mensagem: "Mensagem não anexada"
    })

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.id;
        user[name] = value;

        setUser(user);
    }


    function logar() {

        localStorage.removeItem(ACCESS_TOKEN + "_menu");
        console.log(localStorage.accessToken)
        console.log(user)
        Service.LOGIN("/api/login", user)
            .then(r => {
                console.log(r);
                localStorage.setItem(ACCESS_TOKEN, r.accessToken);
                localStorage.setItem("perfil", r.perfil);
                window.location.href = "/home";
                console.log(localStorage.accessToken)
            }).catch(error => {
                console.log("ERRO: ", error);
                setMensagem({
                    tipo: "error",
                    message: "Problema ao verificar credenciais do usuário. Efetue novo login."
                })
            });

    }

    function setMensagem(alerta) {
        console.log("Mensagem: ", alerta);
        let a;
        if (typeof alerta === 'object') {
            console.log("Mensagem(object): ", alerta);
            a = {
                tipo: alerta && alerta.tipo ? alerta.tipo : "info",
                duracao: alerta && alerta.duracao ? alerta.duracao : 5000,
                mensagem: alerta.message
            }

        } else {
            a = {
                tipo: 'info',
                duracao: 10000,
                mensagem: alerta
            }
        }
        setOpen(true);
        setMensagemAlerta(a);
        if (a && !!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setOpen(false);
            }, a.duracao)
        }
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <HeaderLogin />
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '80vh'
                    }}
                >
                    {open &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity={mensagemAlerta.tipo}>
                                {mensagemAlerta.titilo && <AlertTitle>{mensagemAlerta.titilo}</AlertTitle>}
                                {mensagemAlerta.mensagem}
                            </Alert>
                        </Stack>
                    }
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
                    <img src="ico-logo-gdf.svg" width={185} style={{ marginTop: '40px' }} />
                    {/* </Avatar> */}
                    <div style={{ marginTop: '60px' }} />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Login"
                        name="login"
                        autoComplete="login"
                        autoFocus
                        onChange={handleInputChange}

                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInputChange}

                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => logar()}
                    >
                        Entrar
                    </Button>
                    <Grid container style={{marginLeft: '25px'}}>
                        <Grid item xs={4}>
                            <Link variant="body2">
                                Esqueci a senha
                            </Link>
                        </Grid>

                        <Grid item xs={4} >
                            <Link variant="body2">
                                Suporte ao usuário
                            </Link>
                        </Grid>

                        <Grid item xs={4}>
                            <Link variant="body2" style={{marginLeft: '10px'}}>
                                Solicitar Acesso
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <FooterLogin />
        </ThemeProvider>
    );
}