import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

export default function SearchField(pros) {

    const {doAction, findOnPress} = pros;

    const handleClick=()=>{
        const v = document.getElementsByName("campo_busca")[0].value;
        if( doAction) doAction(v)
    }
    const handleKeyDown=(e)=>{
        if( e.key === 'Enter'){
            e.preventDefault();
            handleClick();
        }
    }
    const handleKey=(e)=>{
        if( e.key === 'Enter'){
            e.preventDefault();
        }else{
            if (findOnPress) findOnPress(e.target.value);
        }
    }
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
     
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar Subistituição Tributária"
        inputProps={{ 'aria-label': 'search google maps' }}
        onSubmit={handleClick} 
        onKeyUp={ handleKey }
        onKeyDown={ handleKeyDown }
        name="campo_busca"
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>
  );
}
