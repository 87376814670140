import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles.css';
import { Box, Button, Divider, Grid, Modal, Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import GenericModal from '../GenericModal/GenericModal';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { FlashOnOutlined } from '@mui/icons-material';




const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(2)', mr: 2 }}
  >
    •
  </Box>
);

const Dropzone = (props) => {
  const { onFileUploaded, data } = props;
  const [selectedFile, setSelectedFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({ a1: false, a2: false, a3: false, a4: false })
  var erro = false
  useEffect(() => {
    for (let x in selectedFile) {
      const nameArquivo = selectedFile[x]
      if (validarArquivoCSV(nameArquivo.name) == false) {
        setOpen(false)
        setOpenModal(true)
        setSelectedFile([])
      }
    }
  }, [selectedFile])

  useEffect(() => {
    if (data?.erro) {
      setSelectedFile([])
    }
  }, [data])

  function validarArquivoCSV(nomeArquivo) {
    var regex = /^(\d{6})_(\d{14})_(\d{2})(\d{2})\.csv$/;
    var resultado = regex.test(nomeArquivo);
    const parts = nomeArquivo.split('_');
    const lastPart = nomeArquivo.split('_')[2]?.split('.')[0];
    const type = nomeArquivo.split('.');
    const lastPosition = type[type.length - 1];
    const errors = {
      a1: parts[0]?.length !== 6,
      a2: parts[1]?.length !== 14,
      a3: lastPart?.length !== 4,
      a4: lastPosition === 'csv'
    };
    setValidationErrors(errors);
    return resultado;
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setOpen(true);
    }
    setSelectedFile(acceptedFiles);
    onFileUploaded(acceptedFiles);
  }, [onFileUploaded]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const clickChoose = (e, file) => {
    e.stopPropagation();
    const updatedFiles = selectedFile.filter((f) => f.name == file.name);
    setSelectedFile(updatedFiles);
    onFileUploaded(updatedFiles);
    setOpen(false)

  };

  const handleClose = () => {
    setSelectedFile([])
    setOpen(false)
    setOpenModal(false)
  };

  const msg = {
    iconErro: (<ReportProblemIcon sx={{ fontSize: 40, color: '#ff9966' }} />),
    TituloMsgErroNCsv: 'Arquivo invalido',
    msgErroNCsv: 'Selecione um arquivo que seja .csv',

  };
  return (
    <div>

      <div className='dropzone' {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='border'>
          <div className='centered-img'>
            {selectedFile.length ? (
              <div>
                {/* Arquivo selecionado: */}
                <br />
                <ul style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%' }}>
                  {selectedFile.map((file) => (
                    <Typography key={file.name} sx={{ mt: 1, textAlign: 'center' }}>
                      Arquivo selecionado: {file.name}
                    </Typography>
                  ))}
                </ul>
                <button className='button button-blue' style={{ marginLeft: '100px' }}>
                  Procure o arquivo .CSV
                </button>
              </div>
            ) : (
              <div className='center-container'>
                <div className='centered-div'>
                  <img className='img-img' src='csv.256x254.png' />
                  <div>Arraste e solte seu arquivo .csv aqui.</div>
                  <br />
                  <div className='button-container'>
                    <button className='button button-blue'>
                      Procure o arquivo .CSV
                    </button>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>

      {open && (
        <Grid container spacing={2} justifyContent="center" alignItems="center" >
          <Modal open={open} onClose={handleClose}>
            <Box sx={styleModal}>
              <Grid container justifyContent="flex-end" sx={{ marginBottom: -2, marginLeft: 2 }} >
                <Button
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
              </Grid>
              <Box id={'content-id'}>
                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ marginTop: 0 }}>
                  <WarningAmberIcon sx={{ fontSize: 40, color: '#ff9966' }} />
                  <Typography id="modal-modal-title" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: 1, fontSize: 20 }}>
                    Atenção
                  </Typography>
                </Grid>
                <br />
                <Divider />
                <Typography id="modal-modal-description" sx={{ mt: 2, ml: 1 }}  >
                  Selecione apenas um arquivo!
                  <br />
                </Typography>
                {selectedFile &&
                  selectedFile.map((file) => (
                    <li key={file.name} style={{ marginTop: 5, listStyle: 'none' }}>
                      <Button variant="text" onClick={(e) => clickChoose(e, file)}>{file.name}</Button>
                    </li>
                  ))
                }
              </Box>
            </Box>
          </Modal>
        </Grid>
      )}

      <Grid container spacing={2} justifyContent="center" alignItems="center" >

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
        >

          <Box sx={styleModalArquivo}>
            <Grid container justifyContent="flex-end" sx={{ marginBottom: -3, marginLeft: 2 }}>
              <Button
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Grid>
            <Box id={'content-id'}>
              <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ marginTop: 0 }}>
                <WarningAmberIcon sx={{ fontSize: 40, color: '#ff9966' }} />

                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', marginLeft: 1 }}>
                  Arquivo não atende o padrão obrigatório
                </Typography>
              </Grid>
              <br />
              <Divider />
              <Grid sx={{ m: 1 }}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  As regras informadas abaixo seguem o padrão exigido pela:&nbsp;
                  <a style={{ textDecoration: 'none', color: '#6BA7ED' }} target="_blank" rel="noopener noreferrer" href='https://ww1.receita.fazenda.df.gov.br/legislacao/visualizar-legislacao?txtNumero=16&txtAno=2019&txtTipo=8&txtParte=SUREC&identificacao=Instru%C3%A7%C3%A3o%20Normativa%20n%C2%BA%2016%2F2019%20SUREC'>
                    Instrução Normativa SUREC/SEF/SEEC N° 16
                  </a>
                </Typography>
                <br />
                <Typography id="modal-modal-description" sx={{ mt: 2 }} >
                  O arquivo deverá ser nomeado atendendo ao seguinte padrão: PERIODO_CNPJ_VOLUME
                </Typography>

                {validationErrors.a1 && (
                  <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
                    {bull} PERIODO: AAAAMM (Ano com quatro dígitos e mês com dois dígitos);
                  </Typography>
                )}
                {validationErrors.a2 && (
                  <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
                    {bull} CNPJ: CNPJ do requerente, emitente dos documentos fiscais de saída;
                  </Typography>
                )}
                {validationErrors.a3 && (
                  <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
                    {bull} VOLUME: NNQQ (Número do volume com dois dígitos e quantidade de volumes com dois dígitos).
                  </Typography>
                )}
                {!validationErrors.a4 && (
                  <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
                    {bull} TIPO DO ARQUIVO: Arquivo deverá ser .csv
                  </Typography>
                )}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  O arquivo selecionado precisa ser corrigido antes de prosseguir.
                  Por favor, revise e ajuste o arquivo conforme necessário para dar seguimento.
                  <br />
                </Typography>

              </Grid>

            </Box>
            <Stack
              direction="row-reverse"
              spacing={2}
            >

              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '20ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <Button variant="contained"
                  onClick={handleClose}
                  sx={{ fontWeight: '600' }}
                >
                  SAIR
                </Button>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </Grid>
    </div>
  );
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  pt: 1,
  maxHeight: '80vh'
}

const styleModalArquivo = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: 3
}

export default Dropzone;
