import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import Service from '../service/Service';
import { useNavigate, useOutletContext } from "react-router-dom";
import SearchField from './SearchField';
import TablePaginationActions from './TablePaginationActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function VerArquivos() {
    const navigate = useNavigate();
    const [setMensagem] = useOutletContext();
    const [lista, setLista] = React.useState();
    const [listaFiltro, setListaFiltro] = React.useState();

    // const [sub, setSub] = React.useState();
    const [row, setRow] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() =>{
        Service.GET('/api/entradaArquivo/todos')
        .then(r => {
            setLista(r);
            setListaFiltro(r);
            setRow(r.slice(0, rowsPerPage) );
        }).catch(error => {
          // console.log("erro:", error)
          if (typeof error === 'string') {
              setMensagem(error)
          } else if (typeof error === 'object') {
              setMensagem(error);
          } else {
              setMensagem('Oops! Something went wrong. Please try again!');
          }
      });
    },[])


  const handleDoubleClick=(item)=>{
    console.log( item );
    navigate("/acompanhaProcessamento/"+item.id, { state: { key: "value" } });
  }
  const handleFiltro = (txt)=>{
    console.log( txt );
  }
  const handleChange = (event) => {
    console.log(event.target.checked);
    if( event.target.checked){
        let arr = [];
        for(let x in lista){
            const item = lista[x];
            if( item.erro && item.erro.length > 0 ){
                arr.push(item)
            }
        }
        setListaFiltro(arr);
        arr = arr.slice(0, rowsPerPage);
        setRow( arr );
        setPage(0);
    }else{
        setListaFiltro(lista);
        setRow(lista.slice(0, rowsPerPage) );
    }
  };
  const handleFindOnPress = (txt)=> {
    let arr = [];
    for(let x in lista){
        const item = lista[x];
        for(let h in item){
            if( !item[h] ) continue;
            if( typeof item[h] === 'object'){
                if (hasOwnProperty.call(item[h], "length")) {
                    const v = item[h][ item[h].length-1]
                    if( v.toUpperCase().includes(txt.toUpperCase() )){
                        arr.push( item );
                        break;  
                    }
                }
            }else if( item[h] && (item[h]+"").toUpperCase().includes( txt.toUpperCase()) ){
                arr.push( item );
                break;
            }
        }
    }
    setListaFiltro(arr);
    arr = arr.slice(0, rowsPerPage);
    setRow( arr );
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    if( newPage === 0 ){
        const arr = listaFiltro.slice(0, rowsPerPage);
        setRow( arr );
    }else{
        const arr = listaFiltro.slice(newPage * rowsPerPage, (1+newPage) *rowsPerPage);
        setRow( arr );
    }
    setPage(newPage);   
    
  };
  const handleChangeRowsPerPage = (event) => {
    if( event.target.value === -1){
        if( listaFiltro.length > 100){
            alert("Maximo da pagina é 100");
            const arr = listaFiltro.slice(0, 100);
            setRow( arr );
            setRowsPerPage(100);
            setPage(0);
        }
    }
    const arr = listaFiltro.slice(0, event.target.value);
    setRow( arr );
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function formataData(dt){
    if( !dt )return "";
    return (new Date(dt)).toLocaleString();
  }
  function trataErro(er){
    let ret = "";
    for( let x in er){
        const item = er[x].split(";");
        if( x > 0) ret +=".\n ";
        ret += item[item.length-1];
        
    }
    return ret;
  }
  return (
    <TableContainer component={Paper}>
        <Stack direction="row" spacing={2}>

        <SearchField 
            doAction={handleFiltro}
            findOnPress={handleFindOnPress}
        />
        <FormControlLabel control={<Checkbox onChange={handleChange}/>} label="Filtrar com erro" />
        </Stack>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell>Data Inclusão</StyledTableCell>
            <StyledTableCell>Fluxo</StyledTableCell>
            <StyledTableCell>Erro</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row && row.map((row) => (
            <StyledTableRow key={row.id} onDoubleClick={ ()=> handleDoubleClick(row)}>
                <StyledTableCell>{row.nome}</StyledTableCell>
                <StyledTableCell>{formataData(row.dtInclusao) }</StyledTableCell>
                <StyledTableCell>{row.fluxo[row.fluxo.length-1]}</StyledTableCell>
                <StyledTableCell>{trataErro(row.erro)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            {lista && 
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              labelRowsPerPage={"Linhas por pagina"}
              colSpan={6}
              count={listaFiltro.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Linhas por pagina',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
            }
          </TableRow>
        </TableFooter>

      </Table>
    </TableContainer>
  );
}